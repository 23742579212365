import * as React from 'react'
import * as Styles from './blogItem.module.scss'
import c from 'classnames'

const Layout = ({ data, index, image }) => {
	let content = undefined;

	function trimDownToWord(string, maxLength) {
		if (string <= maxLength) {
		  return string;
		}
	  
		let first = string.substr(0, maxLength);
		const second = string.substr(maxLength);
	  
		if (/\w$/.test(first) && /^\w/.test(second)) {
		  first = first.replace(/\b[^\w]*\w+$/, '');
		}
	  
		return first.trim() + '...';
	}

	const regex = /(<([^>]+)>)/ig;

	if(data?.content) {
		content = trimDownToWord(data.content.replace(regex, ''), 100);
	}

	if(data?.excerpt) {
		content = trimDownToWord(data.excerpt.replace(regex, ''), 100);
	}

	let cat = '';
	let catClass = '';
	if (data.categories !== undefined) {
		cat = data.categories.nodes[0].name;
		catClass = 'blogCardLatestCat' + cat;
	}

	const getOrdinalNum = (number) => {
		let selector;
	  
		if (number <= 0) {
		  selector = 4;
		} else if ((number > 3 && number < 21) || number % 10 > 3) {
		  selector = 0;
		} else {
		  selector = number % 10;
		}
	  
		return number + ['th', 'st', 'nd', 'rd', ''][selector];
	};

	function formatDate(date) {
		const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
	
			month = months[month];
		if (day.length < 2) 
			day = '0' + day;
	
		day = getOrdinalNum(day);
		return day + ' ' + month + ' ' + year;
	}
	
	let date = formatDate(data.date);

	return (
		<div className={Styles.blogCard}> 
			<a href={data.uri} className={Styles.blogCardImage} style={{ backgroundImage: `url('${image}')`}}></a> 
			<div className={Styles.blogCardRight}>
				<div className={Styles.blogCardUpper}> 
					{
						(cat) ? 
						<div className={Styles.blogCardCat}>
							<div className={c(`${Styles.blogCardLatestCat} ${Styles[catClass]}`)}>
								<a href={`/blog/category/${cat.toLowerCase()}`} data-val="13">{cat}</a>
							</div>
						</div>
						: ''
					}
					<p>{date}</p>
				</div> 
				<div className={Styles.blogCardTitle}> 
					<a href={data.uri} dangerouslySetInnerHTML={{__html: data.title}}></a>
				</div> 
				{
					(content) ?
					<div className={Styles.blogCardDesc}> 
						<p dangerouslySetInnerHTML={{__html: content}}></p> 
					</div>
					: ''
				}
			</div>
		</div>
	)
}

export default Layout