// extracted by mini-css-extract-plugin
export var blogCard = "blogItem-module--blogCard--2883d";
export var blogCardCat = "blogItem-module--blogCardCat--fb25d";
export var blogCardDesc = "blogItem-module--blogCardDesc--0206c";
export var blogCardImage = "blogItem-module--blogCardImage--5b345";
export var blogCardLatestCat = "blogItem-module--blogCardLatestCat--469e6";
export var blogCardLatestCatAdvice = "blogItem-module--blogCardLatestCatAdvice--5dafd";
export var blogCardLatestCatBehavior = "blogItem-module--blogCardLatestCatBehavior--b5fee";
export var blogCardLatestCatBehaviour = "blogItem-module--blogCardLatestCatBehaviour--48d8e";
export var blogCardLatestCatFun = "blogItem-module--blogCardLatestCatFun--c8dad";
export var blogCardLatestCatHealth = "blogItem-module--blogCardLatestCatHealth--fc3dd";
export var blogCardRight = "blogItem-module--blogCardRight--c0c70";
export var blogCardTitle = "blogItem-module--blogCardTitle--d03db";
export var blogCardUpper = "blogItem-module--blogCardUpper--91c15";
export var postLatestCard = "blogItem-module--postLatestCard--e3ece";
export var postLatestDesc = "blogItem-module--postLatestDesc--b62e8";
export var postLatestImage = "blogItem-module--postLatestImage--7c07b";
export var postLatestInfo = "blogItem-module--postLatestInfo--a8ed5";
export var postLatestTitle = "blogItem-module--postLatestTitle--60204";
export var small = "blogItem-module--small--7bc78";