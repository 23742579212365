import * as React from 'react'
import * as Styles from './featuredHero.module.scss'
import c from 'classnames'

const Layout = ({ section, title, incoming }) => {
	let introduction = (section.introduction) ? section.introduction : false;
	let pageTitle = (section.pageTitle) ? section.pageTitle : section.title;
	let showBreadcrumbs = (section.showBreadcrumbs) ? section.showBreadcrumbs : false;
	let image = (section.heroImage) ? section.heroImage.sourceUrl : false;
	let bc_schema = [{
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": []
	}];
	let myArray = [];
	let schema = '';

	for (let index = 0; index < incoming.length; index++) {
		const element = incoming[index];
		myArray.push({
			"@type": "ListItem",
			"position": index + 1,
			"name": element.text,
			"item": [{"@type" : 'Thing', "@id": "https://petted.com" + element.url }]
		});
	}
	
	bc_schema[0].itemListElement = myArray;
	schema = '<script type="application/ld+json">' + JSON.stringify(bc_schema) + '</script>';

	return (
		<div className={c('col', 'align-text-center', Styles.featuredHero, {[Styles.featuredHeroWithImage]: image != false})}>
			<h1 className={Styles.pageHeaderTitle} dangerouslySetInnerHTML={{__html:pageTitle}}></h1>
			{
				(introduction) ? 
					<p className={Styles.pageHeaderTitle} dangerouslySetInnerHTML={{__html:introduction}}></p>
				: ''
			}  
			{
				(showBreadcrumbs) ? 
					<p className={Styles.breadcrumbs}>
						<span>
							<span>
								<a href="/">Home</a> &gt; 
								{
									(incoming) ?
										<span>
											{
												incoming.map((item, index) => {
													if(index < incoming.length - 1) {
														return (
															<span>
																<a href={item.url}>{item.text}</a> &gt; 
															</span>
														)
													} else {
														return (
															<span className={Styles.breadcrumb_last} aria-current="page">{item.text}</span>
														)
														
													}
													
												})
											}
										</span>
									: ''
								}
							</span>
						</span>
					</p>
				: ''
			}
			{
				(schema) ? <div dangerouslySetInnerHTML={{__html:schema}}></div> : ''
			}
		</div>
	)
}

export default Layout