// extracted by mini-css-extract-plugin
export var blogCategories = "blogHome-module--blogCategories--f1fd1";
export var blogCategoriesMobile = "blogHome-module--blogCategoriesMobile--28434";
export var blogCategoryWrapper = "blogHome-module--blogCategoryWrapper--5c765";
export var blogFeatured = "blogHome-module--blogFeatured--3fa8e";
export var blogFeaturedCard = "blogHome-module--blogFeaturedCard--6b272";
export var blogFeaturedCat = "blogHome-module--blogFeaturedCat--b63bb";
export var blogFeaturedTitle = "blogHome-module--blogFeaturedTitle--011e4";
export var blogFeaturedTitleTop = "blogHome-module--blogFeaturedTitleTop--c35ac";
export var blogFeaturedWrapper = "blogHome-module--blogFeaturedWrapper--f5901";
export var blogLatestCat = "blogHome-module--blogLatestCat--8ec9e";
export var blogLatestCatAdvice = "blogHome-module--blogLatestCatAdvice--d6d41";
export var blogLatestCatBehavior = "blogHome-module--blogLatestCatBehavior--8febe";
export var blogLatestCatBehaviour = "blogHome-module--blogLatestCatBehaviour--5fbbb";
export var blogLatestCatFun = "blogHome-module--blogLatestCatFun--f4128";
export var blogLatestCatHealth = "blogHome-module--blogLatestCatHealth--12314";
export var blogNumbers = "blogHome-module--blogNumbers--5719c";
export var blogNumbersInner = "blogHome-module--blogNumbersInner--6eb29";
export var blogQuote = "blogHome-module--blogQuote--cf889";
export var blogQuoteLeft = "blogHome-module--blogQuoteLeft--a6937";
export var blogQuoteRight = "blogHome-module--blogQuoteRight--15d72";
export var blogResults = "blogHome-module--blogResults--7aeb3";
export var blogWrapper = "blogHome-module--blogWrapper--40d7b";
export var hasCat = "blogHome-module--hasCat--a4658";
export var isActive = "blogHome-module--is-active--36dc9";
export var postFeaturedPosts = "blogHome-module--postFeaturedPosts--02122";
export var postFeaturedPostsWrapper = "blogHome-module--postFeaturedPostsWrapper--16596";
export var tnsControls = "blogHome-module--tns-controls--6c3d1";
export var tnsInner = "blogHome-module--tns-inner--4e9b2";
export var tnsItem = "blogHome-module--tns-item--53c95";